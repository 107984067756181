import React from "react";

import "./Selector.scss";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronCircleDown } from "@fortawesome/free-solid-svg-icons";

const Selector = (props) => {
	return (
		<div
			className={props.isSelected ? "option selected" : "option"}
			onClick={() => {
				props.onClick();
			}}>
			<img alt={props.type} src={props.image} />
			<div className="option-name">{props.children}</div>
			{props.isSelected ? (
				<div className="checkbox">
					<FontAwesomeIcon icon={faChevronCircleDown} />
				</div>
			) : null}
		</div>
	);
};

export default Selector;
