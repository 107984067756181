import React, { useState } from "react";

import "./Selectors.scss";

import "../components/Selector/Selector";
import Selector from "../components/Selector/Selector";

import blank from "./preview-imgs/default.png";
import typhoon from "./preview-imgs/1.png";
import practice from "./preview-imgs/2.png";

const Background = (props) => {
	const COUNT = 3;
	let [selectedOption, setSelectedOption] = useState(props.current);

	let onClickOption = (option) => {
		setSelectedOption(option);
		props.select(option);
	};

	let selectors = [];

	let selectors_imgs = [blank, typhoon, practice];

	let selectorNames = ["없음", "태풍방지", "연습실"];

	for (let i = 0; i < COUNT; i++) {
		selectors.push(
			<Selector
				isSelected={selectedOption !== i ? false : true}
				key={i}
				type="background"
				image={selectors_imgs[i]}
				onClick={() => {
					onClickOption(i);
				}}>
				{selectorNames[i]}
			</Selector>
		);
	}

	return (
		<div id="background-select" className="selector-area">
			<div className="title-wrapper">
				<div className="title">반려돌의 방을 꾸며 주세요.</div>
				{/* <div className="subtitle"></div> */}
			</div>
			<div className="col-3-grid">{selectors}</div>
		</div>
	);
};

export default Background;
