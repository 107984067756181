import React, { useState } from "react";

import "./Selectors.scss";

import "../components/Selector/Selector";
import Selector from "../components/Selector/Selector";

//Head Type
import blank from "./preview-imgs/default.png";
import strawhat from "./preview-imgs/acc_1.png";
import ribbon from "./preview-imgs/acc_2.png";
import tangerine from "./preview-imgs/acc_3.png";
import buckethat from "./preview-imgs/acc_7.png";
import cat from "./preview-imgs/acc_10.png";
import bunny from "./preview-imgs/acc_11.png";

//Face Type
import facechain from "./preview-imgs/acc_6.png";
import sunglasses from "./preview-imgs/acc_8.png";
import tiger from "./preview-imgs/acc_9.png";

const Accessory = (props) => {
	const FACE_COUNT = 8;
	const HEAD_COUNT = 8;
	// let [selectedOption, setSelectedOption] = useState(props.current);

	// let onClickOption = (option) => {
	// 	setSelectedOption(option);
	// 	props.select(option);
	// };

	let [selectedFace, setSelectedFace] = useState(props.currentFace);
	let [selectedHead, setSelectedHead] = useState(props.currentHead);

	let selectFace = (option) => {
		setSelectedFace(option);
		props.selectFace(option);
	};

	let selectHead = (option) => {
		setSelectedHead(option);
		props.selectHead(option);
	};

	let face = [];

	let face_imgs = [blank, facechain, sunglasses, tiger];
	let face_names = ["없음", "페이스체인", "쫑글라스", "호랑이"];

	let head = [];

	let head_imgs = [blank, strawhat, ribbon, tangerine, buckethat, cat, bunny];
	let head_names = ["없음", "밀짚모자", "리본", "귤꼭지", "버킷햇", "고양이 귀", "토끼 귀"];

	for (let i = 0; i < FACE_COUNT; i++) {
		face.push(
			<Selector
				isSelected={selectedFace !== i ? false : true}
				key={i}
				type="accessory"
				image={face_imgs[i]}
				onClick={() => {
					selectFace(i);
				}}>
				{face_names[i]}
			</Selector>
		);
	}

	for (let i = 0; i < HEAD_COUNT; i++) {
		head.push(
			<Selector
				isSelected={selectedHead !== i ? false : true}
				key={i}
				type="accessory"
				image={head_imgs[i]}
				onClick={() => {
					selectHead(i);
				}}>
				{head_names[i]}
			</Selector>
		);
	}

	return (
		<div id="accessory-select" className="selector-area">
			<div className="title-wrapper">
				<div className="title">내 반려돌을 꾸며 주세요.</div>
				{/* <div className="subtitle"></div> */}
			</div>
			<div className="subtitle">얼굴 장식</div>
			<div className="col-3-grid">{face}</div>

			<div className="subtitle">머리 장식</div>
			<div className="col-3-grid">{head}</div>
		</div>
	);
};

export default Accessory;
