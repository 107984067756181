import React, { useState, useEffect } from "react";
import "./App.scss";

import html2canvas from "html2canvas";

import Type from "./selectors/Type";
import Face from "./selectors/Face";
import Accessory from "./selectors/Accessory";
import Background from "./selectors/Background";

import Button from "./components/Button/Button";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faRotateRight, faChevronLeft } from "@fortawesome/free-solid-svg-icons";

// import { TwitterShareButton, TwitterIcon } from "next-share";

function App() {
	/* Set window height */
	const [height, setHeight] = useState({ height: window.innerHeight });

	let resize = () => {
		setHeight({ height: window.innerHeight });
	};

	useEffect(() => {
		window.addEventListener("resize", resize);

		return () => {
			window.removeEventListener("resize", resize);
		};
	});

	/* Options to Select */
	const [type, setType] = useState(0);
	const [face, setFace] = useState(0);
	const [background, setBackground] = useState(0);

	/* Accessories can have multiple types */
	// const [accessory, setAccessory] = useState(0);

	const [headAcc, setHeadAcc] = useState(0);
	const [faceAcc, setFaceAcc] = useState(0);

	/* Proceed and return steps */
	const FINAL_STEP = 3;
	const [currentStep, setCurrentStep] = useState(0);

	let proceedStep = () => {
		setCurrentStep(currentStep + 1);
	};

	let returnStep = () => {
		setCurrentStep(currentStep - 1);
	};

	let reload = () => {
		window.location.reload();
	};

	/* Downloadable Image generator */
	const ref = React.createRef();
	const [finished, setFinished] = useState(false);

	let finishGeneration = () => {
		setFinished(true);
		download();
	};

	let downloadable = new Image();

	let download = () => {
		const element = ref.current;

		html2canvas(element).then((canvas) => {
			downloadable.src = canvas.toDataURL("image/png");
		});
		document.querySelector("main").prepend(downloadable);
	};

	let currentElement;

	switch (currentStep) {
		case 0:
			currentElement = <Type select={setType} current={type} />;
			break;
		case 1:
			currentElement = <Face select={setFace} current={face} type={type} />;
			break;
		case 2:
			currentElement = (
				<Accessory
					selectFace={setFaceAcc}
					selectHead={setHeadAcc}
					currentFace={faceAcc}
					currentHead={headAcc}
					type={type}
				/>
			);
			break;
		case 3:
			currentElement = <Background select={setBackground} current={background} type={type} />;
			break;
		default:
			currentElement = <div />;
			break;
	}

	return (
		<div className="App" style={height}>
			{!finished ? (
				<main id="generator" style={height}>
					<div id="display" ref={ref}>
						<div id="background" className="display-area">
							<img
								alt="selected background"
								src={process.env.PUBLIC_URL + "/images/background/" + background + ".png"}
							/>
						</div>
						<div id="type" className="display-area">
							<img
								alt="selected face type"
								src={process.env.PUBLIC_URL + "/images/type/" + type + ".png"}
							/>
						</div>
						<div id="face" className="display-area">
							<img
								alt="selected face"
								src={process.env.PUBLIC_URL + "/images/face/" + type + "_" + face + ".png"}
							/>
						</div>
						<div id="accessory" className="display-area">
							<img
								style={{ zIndex: 5 }}
								alt="selected accessory"
								src={process.env.PUBLIC_URL + "/images/acc_face/" + type + "_" + faceAcc + ".png"}
							/>
							<img
								style={faceAcc === 2 && headAcc === 1 ? { zIndex: 1 } : { zIndex: 10 }}
								alt="selected accessory"
								src={process.env.PUBLIC_URL + "/images/acc_head/" + type + "_" + headAcc + ".png"}
							/>
							{/* <img
								alt="selected accessory"
								src={
									process.env.PUBLIC_URL + "/images/accessory/" + type + "_" + accessory + ".png"
								}
							/> */}
						</div>
					</div>
					<div id="select-area">{currentElement}</div>
					<div id="button-area" className={currentStep === 0 ? "button-1-grid" : "button-2-grid"}>
						{currentStep === 0 ? null : (
							<Button
								type="secondary"
								onClick={() => {
									returnStep();
								}}>
								<FontAwesomeIcon icon={faChevronLeft} />
							</Button>
						)}

						{currentStep !== FINAL_STEP ? (
							<Button
								onClick={() => {
									proceedStep();
								}}>
								다음 단계로 넘어가기
							</Button>
						) : (
							<Button
								onClick={() => {
									finishGeneration();
								}}>
								다음 단계로 넘어가기
							</Button>
						)}
					</div>
				</main>
			) : (
				<main id="after-generation" style={height}>
					<div id="info-text">
						나만의 반려돌을 만드는 데 성공했어요! <br />
						자랑을 해 보는 건 어떤가요?
					</div>
					<div id="button-area">
						{/* <TwitterShareButton
							url={"https://github.com/next-share"}
							title={"next-share is a social share buttons for your next React apps."}>
							<TwitterIcon size={32} round />
						</TwitterShareButton> */}
						<Button>📸 나만의 돌쫑이 다운로드</Button>
						<Button
							type="secondary"
							onClick={() => {
								reload();
							}}>
							<FontAwesomeIcon icon={faRotateRight} /> 다시 만들기
						</Button>
					</div>
				</main>
			)}
		</div>
	);
}

export default App;
