import React, { useState } from "react";

import "./Selectors.scss";

import "../components/Selector/Selector";
import Selector from "../components/Selector/Selector";

import blank from "./preview-imgs/default.png";
import face_1 from "./preview-imgs/face_1.png";
import face_2 from "./preview-imgs/face_2.png";
import face_3 from "./preview-imgs/face_3.png";
import face_4 from "./preview-imgs/face_4.png";
import face_5 from "./preview-imgs/face_5.png";
import face_6 from "./preview-imgs/face_6.png";
import face_7 from "./preview-imgs/face_7.png";

const Face = (props) => {
	const COUNT = 8;
	let [selectedOption, setSelectedOption] = useState(props.current);

	let onClickOption = (option) => {
		setSelectedOption(option);
		props.select(option);
	};

	let selectors = [];

	let selectors_imgs = [blank, face_1, face_2, face_3, face_4, face_5, face_6, face_7];

	let selectorNames = [
		"없음",
		"돌쫑",
		"돌민",
		"아기돌떡구이",
		"10시 10분",
		"우아해",
		"안경..",
		"닫어아버지",
	];

	for (let i = 0; i < COUNT; i++) {
		selectors.push(
			<Selector
				isSelected={selectedOption !== i ? false : true}
				key={i}
				type="face"
				image={selectors_imgs[i]}
				onClick={() => {
					onClickOption(i);
				}}>
				{selectorNames[i]}
			</Selector>
		);
	}

	return (
		<div id="face-select" className="selector-area">
			<div className="title-wrapper">
				<div className="title">내 반려돌의 얼굴 표정을 골라 주세요.</div>
				{/* <div className="subtitle"></div> */}
			</div>
			<div className="col-3-grid">{selectors}</div>
		</div>
	);
};

export default Face;
