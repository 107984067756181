import React, { useState } from "react";

import "./Selectors.scss";

import "../components/Selector/Selector";
import Selector from "../components/Selector/Selector";

import type_0 from "./preview-imgs/type_0.png";
import type_1 from "./preview-imgs/type_1.png";

const Type = (props) => {
	let [selectedOption, setSelectedOption] = useState(props.current);

	let onClickOption = (option) => {
		setSelectedOption(option);
		props.select(option);
	};

	let selectors = [];

	let selectors_imgs = [type_0, type_1];

	let selectorNames = ["돌쫑 타입", "도돌 타입"];

	for (let i = 0; i < 2; i++) {
		selectors.push(
			<Selector
				isSelected={selectedOption !== i ? false : true}
				key={i}
				image={selectors_imgs[i]}
				type="type"
				onClick={() => {
					onClickOption(i);
				}}>
				{selectorNames[i]}
			</Selector>
		);
	}

	return (
		<div id="background-select">
			<div className="title-wrapper">
				<div className="title">내 반려돌의 생김새를 골라 주세요.</div>
				{/* <div className="subtitle"></div> */}
			</div>
			<div className="col-3-grid selector-area">{selectors}</div>
		</div>
	);
};

export default Type;
