import React from "react";

import "./Button.scss";

const Button = (props) => {
	let onClickButton = () => {
		props.onClick();
	};

	return (
		<button
			className={`styled-button ` + props.type}
			onClick={() => {
				onClickButton();
			}}
		>
			{props.children}
		</button>
	);
};

export default Button;
